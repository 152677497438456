const QUERIES = {
  USERS_LIST: 'users-list',
  STORES_LIST: 'stores-list',
  GRADES_LIST: 'grades-list',
  COMPANIES_LIST: 'companies-list',
  ROLES_LIST: 'roles-list',
  PRODUCTS_LIST: 'products-list',
  BRANDS_LIST: 'brands-list',
  CATS_LIST: 'cats-list',
  PRICES_LIST: 'prices-list',
  PRICELISTS_LIST: 'priceList-list',
}

export {QUERIES}
