import { FC, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
import { MenuTestPage } from '../pages/MenuTestPage';

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const StoresPage = lazy(() => import('../modules/apps/store-management/StoresPage'))
  const GradesPage = lazy(() => import('../modules/apps/grade-management/GradesPage'))
  const CompaniesPage = lazy(() => import('../modules/apps/company-management/CompaniesPage'))
  const AdvancedPricesPage = lazy(
    () => import('../modules/apps/advanced-price-management/AdvancedPricesPage')
  )
  const BrandsPage = lazy(() => import('../modules/apps/brand-management/BrandsPage'))
  const CatsPage = lazy(() => import('../modules/apps/cat-management/CatsPage'))
  const PricesPage = lazy(() => import('../modules/apps/price-management/PricesPage'))
  const PriceListsPage = lazy(() => import('../modules/apps/pricelist-management/PriceListsPage'))
  const CustomerPricesPage = lazy(() => import('../modules/apps/customer-price/CustomerPricePage'))
  const ProductPricesPage = lazy(() => import('../modules/apps/product-prices/ProductPricesPage'))
  const ProductsPage = lazy(() => import('../modules/apps/product-management/ProductsPage'))
  const RolesPage = lazy(() => import('../modules/apps/role-management/RolesPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/store-management/*'
          element={
            <SuspensedView>
              <StoresPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/grade-management/*'
          element={
            <SuspensedView>
              <GradesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/company-management/*'
          element={
            <SuspensedView>
              <CompaniesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/role-management/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/brand-management/*'
          element={
            <SuspensedView>
              <BrandsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/cat-management/*'
          element={
            <SuspensedView>
              <CatsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/product-management/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/price-management/*'
          element={
            <SuspensedView>
              <PricesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/advanced-price-management/*'
          element={
            <SuspensedView>
              <AdvancedPricesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/pricelist-management/*'
          element={
            <SuspensedView>
              <PriceListsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/customer-price/*'
          element={
            <SuspensedView>
              <CustomerPricesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/product-prices/*'
          element={
            <SuspensedView>
              <ProductPricesPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes };

